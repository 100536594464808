<template>
	<div>
		<div class="topbg">
			<div class="alltitle">
				高新技术<span>企业</span>认定 税收减免<span>40%</span>
			</div>
			<div class="libg">
				政府奖励最高<span>￥2,000,000</span> /
				获得国家颁发的高新企业证书
			</div>
			<div class="searbox">
				<div class="cetabbox">
					<div class="tabli" :class="{ tabactive: currentIndex == index }" v-for="(item, index) in tablist"
						:key="index" @click="seartab(index)">
						{{ item.name }}
					</div>
				</div>
				<div class="disou">
					<div class="dileft">
						<input type="text" class="diput" v-model="search" placeholder="请输入要搜索的内容" />
						<img src="../../assets/img/images/search_03.png" class="searpic" alt="" />
						<div class="huibox" @click="submitForm">汇一下</div>
					</div>
					<div class="huotxt">或</div>
					<div class="xubox" @click="goTokefu">需求发布</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
  data() {
    return {
      tablist: [{
      	id: 1,
      	name: '政策'
      }, {
      	id: 2,
      	name: '活动'
      }, {
      	id: 3,
      	name: '智库'
      }, {
      	id: 4,
      	name: '成果'
      }, {
      	id: 5,
      	name: '专利'
      }],
      currentIndex: 0,
	  search:''
    };
  },
  created() {},

  methods: {
	  // 搜索切换
	  seartab(index) {
	  	this.currentIndex = index;
	  },
	  goTokefu(){
		  this.$router.push({
			  path: '/need'
		  });
	  },
	  submitForm() {
	        if (this.search.trim() == "") {
	          this.$message.error("请输入关键字");
	        } else {
	          if (this.currentIndex == 1) {
	            let routeData = this.$router.resolve({
	              path: "/member8",
	              query: {
	                keyword: this.search,
	              },
	            });
	            window.open(routeData.href, "_blank");
	          } else if (this.currentIndex == 2) {
	            let routeData = this.$router.resolve({
	              path: "/member7",
	              query: {
	                keyword: this.search,
	              },
	            });
	            window.open(routeData.href, "_blank");
	          } else if (this.currentIndex == 3) {
	            let routeData = this.$router.resolve({
	              path: "/member3",
	              query: {
	                keyword: this.search,
	              },
	            });
	            window.open(routeData.href, "_blank");
	          } else if (this.currentIndex == 4) {
	            let routeData = this.$router.resolve({
	              path: "Patent",
	              query: {
	                keyword: this.search,
	              },
	            });
	            window.open(routeData.href, "_blank");
	          } else {
	            let routeData = this.$router.resolve({
	              path: "/member5",
	              query: {
	                keyword: this.search,
	              },
	            });
	            window.open(routeData.href, "_blank");
	          }
	        }
	      },
  },
};
</script>

<style scoped lang="less">
 .topbg {
	margin-top: 136px;
 	width: 100%;
 	height: 500px;
 	background-image: url("../../assets/img/images/banner_02.png");
 	background-size: 100% 100% center;
 
 	.alltitle {
 		text-align: center;
 		padding-top: 94px;
 		font-size: 60px;
 		color: #fff;
 		letter-spacing: 3px;
 		font-weight: bold;
 
 		span {
 			color: #ff7200;
 			font-size: 60px;
 			font-weight: bold;
 		}
 	}
 
 	.libg {
 		background: rgba(255, 255, 255, 0.3);
 		height: 42px;
 		width: 520px;
 		border-radius: 30px;
 		margin: 20px auto 38px;
 		text-align: center;
 		font-size: 16px;
 		color: #fff;
 		line-height: 42px;
 
 		span {
 			color: #ff7200;
 		}
 	}
 
 	.searbox {
 		margin: 0 auto;
 		width: 890px;
 
 		.disou {
 			display: flex;
 			align-items: center;
 			justify-content: space-between;
 
 			.dileft {
 				display: flex;
 				align-items: center;
 				justify-content: space-between;
 				width: 730px;
 				height: 60px;
 				background: #fff;
 
 				.diput {
 					padding: 0 14px;
 					font-size: 16px;
 					color: #333333;
 					flex-grow: 1;
 				}
 
 				.searpic {
 					width: 24px;
 					height: 24px;
 					flex-shrink: 0;
 					margin-right: 20px;
 				}
 
 				.huibox {
 					width: 88px;
 					height: 60px;
 					background: #ff7200;
 					text-align: center;
 					line-height: 60px;
 					color: #ffffff;
 					font-size: 16px;
 					cursor: pointer;
 				}
 			}
 
 			.huotxt {
 				font-size: 14px;
 				color: #fff;
 			}
 
 			.xubox {
 				width: 108px;
 				height: 60px;
 				background: #ff7200;
 				text-align: center;
 				line-height: 60px;
 				color: #ffffff;
 				font-size: 16px;
 				cursor: pointer;
 			}
 		}
 	}
 
 	.cetabbox {
 		display: flex;
 		align-items: center;
 		padding-bottom: 10px;
 		padding-left: 15px;
 
 		.tabli {
 			font-size: 18px;
 			color: #ffffff;
 			margin-right: 45px;
 			cursor: pointer;
 		}
 
 		.tabactive {
 			color: #ff7200;
 		}
 	}
 }
@media screen and (max-width: 1200px) {
	.topbg{
		margin-top: 15rem;
	}
	.topbg .cetabbox{
		padding-bottom: 1.5rem;
	}
	.topbg .cetabbox .tabli{
		font-size: 2rem;
	}
	.topbg .searbox .disou .dileft .diput{
		font-size: 1.6rem;
	}
	.topbg .searbox .disou .dileft .huibox{
		width: 7rem;
		height: 100%;
		font-size: 1.6rem;
		line-height: 4rem;
	}
	.topbg .searbox .disou .huotxt{
		font-size: 1.6rem;
	}
	.topbg .searbox .disou .xubox{
		font-size: 1.6rem;
		width: 8rem;
		line-height: 4rem;
		height: 4rem;
	}
	.topbg .searbox .disou .dileft{
		width: 32rem;
		height: 4rem;
	}
	.topbg .libg{
		margin: 2.5rem auto;
		width: 90%;
		height: 3rem;
		line-height: 3rem;
		border-radius: 45px;
		font-size: 1.6rem;
	}
	.topbg .alltitle{
		padding-top: 3.5rem;
	}
}
</style>
