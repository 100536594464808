<template>
  <div class="search-content">
    <div class="search">
      <ul class="search-type-list">
        <li
          class="search-type-item"
          :class="idx === changeTab ? 'active' : ''"
          @click="Csort(i, idx)"
          v-for="(i, idx) in tabList"
          :key="idx"
        >
          {{ i }}
        </li>
      </ul>

      <div class="Search_i">
        <el-input
          placeholder="请输入内容"
          class="input-with-select"
          :value="value"
          clearable
          @input="$emit('input', $event)"
          @keyup.enter.native="submitForm(value)"
        >
        </el-input>
        <el-button
          class="search-btn"
          @click="submitForm(value)"
          icon="el-icon-search"
        ></el-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Input",
  props: {
    value: String,
  },
  data() {
    return {
      tabList: ["政策", "活动", "智库", "成果", "专利"],
      changeTab: 0,
    };
  },
  methods: {
    Csort(item, idx) {
      this.changeTab = idx;
      // this.$emit("changeTab", this.changeTab);
      // console.log(this.changeTab);
    },
    submitForm(formName) {
      if (formName.trim() == "") {
        this.$message.error("请输入关键字");
      } else {
        if (this.changeTab == 1) {
          let routeData = this.$router.resolve({
            path: "/member8",
            query: {
              keyword: formName,
            },
          });
          window.open(routeData.href, "_blank");
        } else if (this.changeTab == 2) {
          let routeData = this.$router.resolve({
            path: "/member7",
            query: {
              keyword: formName,
            },
          });
          window.open(routeData.href, "_blank");
        } else if (this.changeTab == 3) {
          let routeData = this.$router.resolve({
            path: "/member3",
            query: {
              keyword: formName,
            },
          });
          window.open(routeData.href, "_blank");
        } else if (this.changeTab == 4) {
          let routeData = this.$router.resolve({
            path: "Patent",
            query: {
              keyword: formName,
            },
          });
          window.open(routeData.href, "_blank");
        } else {
          let routeData = this.$router.resolve({
            path: "/member5",
            query: {
              keyword: formName,
            },
          });
          window.open(routeData.href, "_blank");
        }
      }
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.el-input {
  font-size: 20px;
  // box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.4);
  // border-radius: 10px;
}
/deep/.el-input__inner {
  height: 62px;
  line-height: 62px;
  border: 0;
  width: 103%;
}
.Search_i {
  width: 100%;
}
/deep/.el-input {
  width: 85%;
}

.search-btn {
  width: 15%;
  height: 62px;
  text-align: center;
  background-color: #fff;
  border-radius: 5px;
  border: 0;
  margin-bottom: 2px;
}

 
</style>