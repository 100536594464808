<template>
  <div class="service">
	  <home-swiper> </home-swiper>
    <!-- 广告ban -->
    <!-- <div class="kf_ban"></div>
    <FromList v-model="search">
    </FromList> -->
    <!-- 服务发布 --- 服务商入驻-->
    <div class="w">
      <!-- 服务发布 -->
      <div class="zhans clearfix">
        <div class="clearfix">
          <div class="L left">
            <div class="L_img">
              <p class="L_one">服务发布</p>
              <p class="explain">
                企业创新服务交易市场
              </p>
              <div class="to">
                <p class="to_zt" @click="release">
                  <span>进入服务发布入口</span>
                </p>
                <!-- <p class="to_zt" @click="info">
                  <span>进入服务商信息</span>
                </p> -->
              </div>
            </div>
          </div>
          <div class="R right">
            <div class="R_con">
              <div class="pea-title">
                <div class="one-content">
                  <div class="copy-title">
                    <div class="copy-con copy-con1">
                      <span></span>
                      <h3>请在下方留下您的信息</h3>
                      <span></span>
                    </div>
                  </div>
                </div>
              </div>     
              <el-form
                :label-position="labelPosition"
                label-width="80px"
                :model="Settle"
              >
                <el-form-item label="企业名称">
                  <el-input
                    v-model="Settle.title"
                    placeholder="请填写企业名称"
                  ></el-input>
                </el-form-item>
                <el-form-item label="手机号">
                  <el-input
                    v-model="Settle.phone"
                    placeholder="请填写手机号"
                    maxlength="11"
                  ></el-input>
                </el-form-item>
              </el-form>
              <div class="subtmit-btn-box">
                <el-button type="primary" @click="info">立即入驻</el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 服务商入驻 -->
      <div class="ruzhu clearfix">
        <div class="pea-title">
          <div class="one-content">
            <div class="copy-title">
              <div class="copy-con">
                <h3>服务商入驻</h3>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="left Left">
            <div class="li" v-for="(item, index) in List" :key="index">
              <el-row>
                <div class="round">
                  <i></i>
                </div>
                <h3 class="r_t">{{ item.title }}</h3>
              </el-row>
              <p class="paper">
                {{ item.paper }}
              </p>
            </div>
          </div>
          <div class="center left">
            <div style="margin-top
            :106px;padding:50px 20px">
              <img src="../../../assets/img/kefuyun/demand.27d873c9.png" alt="">
            </div>
          </div>
          <div class="right Right">
            <div class="li" v-for="(item, index) in List1" :key="index">
              <el-row>
                <div class="round round1">
                  <i></i>
                </div>
                <h3 class="r_t">{{ item.title }}</h3>
              </el-row>
              <p class="paper">
                {{ item.paper }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 入驻流程 -->
    <div class="process">
      <div class="w">
        <div class="pea-title">
          <div class="one-content">
            <div class="copy-title">
              <div class="copy-con">
                <span></span>
                <h3>入驻流程</h3>
                <span></span>
              </div>
            </div>
          </div>
        </div>
        <div class="line_box">
          <ul class="line">
            <li v-for="l in line" :key="l.index">
              <div><i></i></div>
              <p>{{ l }}</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
	import HomeSwiper from '../../../components/common/Swiper';
import FromList from "../../../common/input";
export default {
  name: "Service_Settled",
  components: {
    FromList,
	HomeSwiper
  },
  data() {
    return {
      search: "",
      labelPosition: "right",
      Settle: {
        title: "",
        phone: "",
      },
      List: [
        {
          icon: " ",
          title: "技术交易服务",
          paper:
            "	围绕技术转移转化及交易环节的服务，主要包括技术需求挖掘、技术成果评价评估、技术合同商务及法务服务",
        },
        {
          icon: "",
          title: "科技金融服务",
          paper:
            "围绕科技金融板块的服务，主要包括知识产权评估、知识产权融资、投贷联动融资、专利保险、成果转化基金等",
        },
      
      ],
      List1: [
        {
          icon: " ",
          title: "知识产权服务",
          paper:
            "	围绕知识产权运营的服务，主要包括知识产权代理、知识产权贯标、知识产权布局、知识产权维权、知识产权基金等",
        },
        {
          icon: "",
          title: "小试中试服务",
          paper:
            "围绕小试中试服务板块，主要包括各行业小试中试打包服务、小试中试基地、仪器设备共享服务等",
        },
      
      ],
      line: [
        "填写入驻申请",
        "人工资料审核",
        "签署平台合作协议",
        "开启服务商店铺",
        "开启接单系统",
      ],
    };
  },
  created() {},
  methods: {
   
    //   进入服务发布入口
    release() {
      this.$router.push({
        path: "/s_release",
      });
    },
    //   进入服务商信息
    info() {
      // if (!/^1[345789]\d{9}$/.test(this.Settle.phone)) {
      //   this.$message.error("请输入正确手机号");
      // } else {
        this.$router.push({
          path: "/s_info",
          query: {
            title: this.Settle.title,
            phone: this.Settle.phone,
          },
        });
      // }
    },
  },
  mounted() {},
};
</script>

<style scoped lang="less">
.service {
  background-color: rgba(248, 249, 250, 100);
  .kf_ban {
    background: url(../../../assets/img/kefuyun/rz1.png) no-repeat center;
    background-size: 100% 100%;
  }
  .search-content {
    top: 250px;
  }
}
.zhans {
  margin-top: 37px;
}

.L {
  width: 300px;
  height: 441px;
  padding-top: 81px;
  background: url(../../../assets/img/kefuyun/rz2.png) no-repeat;
  background-size: 100% 100%;
}

.L_img {
  width: 100%;
  height: 342px;
  background: url(../../../assets/img/kefuyun/rz3.png) no-repeat;
  background-size: 100% 100%;
}
.L_one {
  font-size: 20px;
  color: #f2f5f3;
  font-weight: 700;
  padding: 0 46px 26px 45px;
}
.explain {
  padding-left: 46px;
  padding-right: 45px;
  font-size: 14px;
  line-height: 25px;
  color: #fff;
}
.to {
  padding-left: 46px;
  padding-right: 45px;
  padding-top: 96px;
}
.to_zt {
  display: flex;
  width: 170px;
  height: 36px;
  background: #fafbfb;
  border-radius: 4px;
  margin-top: 14px;
  justify-content: center;
  align-items: center;
  color: #3892eb;
  cursor: pointer;
}
.R {
  width: 869px;
  height: 441px;
  padding: 29px 25px 31px;
  background-color: #3892eb;
  border-radius: 5px;
}
.R .R_con {
  background-color: #fff;
  height: 100%;
  border-radius: 5px;
  padding-left: 176px;
  padding-right: 172px;
}
.copy-con1 span {
  margin: 0 10px;
}
.subtmit-btn-box {
  display: flex;
  justify-content: center;
}
// 服务商入驻

.Left,
.Right {
  width: 435px;
  height: 535px;
  .li {
    height: 260px;
    background-color: #fff;
    border-radius: 5px;
    padding: 31px 22px;
  }
  .li:nth-of-type(1) {
    margin-bottom: 15px;
  }
  .round {
    display: inline-block;
    width: 80px;
    height: 80px;
    background-color: rgba(56, 146, 235, 100);
    border-radius: 50%;
    padding: 25px;
    i {
      display: inline-block;
      width: 30px;
      height: 30px;
      background: url(../../../assets/img/kefuyun/rz_icon1.png) no-repeat center;
      background-size: 100% 100%;
    }
  }
  .li:nth-of-type(2) .round i {
    background: url(../../../assets/img/kefuyun/rz_icon2.png) no-repeat center;
    background-size: 100% 100%;
  }
  .round1 i {
    background: url(../../../assets/img/kefuyun/rz_icon3.png) no-repeat center;
    background-size: 100% 100%;
  }
  .li:nth-of-type(2) .round1 i {
    background: url(../../../assets/img/kefuyun/rz_icon4.png) no-repeat center;
    background-size: 100% 100%;
  }
  .r_t {
    display: inline-block;
    margin-left: 13px;
    font-size: 28px;
  }
  .paper {
    margin-top: 20px;
    line-height: 25px;
    font-size: 18px;
    font-family: SourceHanSansSC-regular;
    color: rgba(105, 105, 105, 100);
  }
}

.center {
  margin-left: 15px;
  width: 300px;
  height: 535px;
    background: url(../../../assets/img/kefuyun/rz2.png) no-repeat;
  background-size: 100% 100%;
}
.process {
  height: 320px;
  margin: 29px 0;
  background-color: rgba(233, 233, 233, 100);
  // padding: 0 440px;
  .line_box {
    margin-top: 75px;
    padding: 0 80px;
  }
  .line {
    position: relative;
    width: 100%;
    height: 3px;
    background: url(../../../assets/img/kefuyun/line.png) no-repeat;
    background-size: 100% 100%;
    li {
      position: absolute;
      top: -40px;
      left: 43px;
      width: 114px;
      text-align: center;
      div {
        margin: 0 auto;
        width: 80px;
        height: 80px;
        background-color: #fff;
        border-radius: 50%;
        padding: 21px;
        i {
          display: inline-block;
          width: 37px;
          height: 37px;
          background: url(../../../assets/img/kefuyun/rzlc1.png) no-repeat;
          background-size: 100% 100%;
        }
      }
      p {
        margin-top: 20px;
        color: rgba(105, 105, 105, 100);
        font-size: 18px;
        font-family: SourceHanSansSC-regular;
      }
    }
    li::after {
      content: "";
      position: absolute;
      top: 32px;
      right: -50px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: rgba(56, 146, 235, 100);
    }
    li:nth-of-type(2) {
      left: 245px;
      i {
        background: url(../../../assets/img/kefuyun/rzlc2.png) no-repeat;
        background-size: 100% 100%;
      }
    }
    li:nth-of-type(3) {
      left: 440px;
      i {
        background: url(../../../assets/img/kefuyun/rzlc3.png) no-repeat;
        background-size: 100% 100%;
      }
    }
    li:nth-of-type(4) {
      left: 650px;
      i {
        background: url(../../../assets/img/kefuyun/rzlc4.png) no-repeat;
        background-size: 100% 100%;
      }
    }
    li:nth-of-type(5) {
      left: 870px;
      i {
        background: url(../../../assets/img/kefuyun/rzlc5.png) no-repeat;
        background-size: 100% 100%;
      }
    }
  }
  .line::before {
    content: "";
    position: absolute;
    top: -10px;
    left: -20px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: rgba(56, 146, 235, 100);
  }
}
@media screen and (max-width: 1200px) {
	.w{
		width: 98%;
		overflow: hidden;
	}
	.L{
		width: 100%;
		height: 20rem;
		background-size: cover;
	}
	.L_one{
		font-size: 2.2rem;
	}
	.explain{
		font-size: 1.6rem;
	}
	.to_zt{
		width: 15rem;
		height: 3rem;
		line-height: 3rem;
		font-size: 1.6rem;
	}
	.R{
		width: 100%;
	}
	.R .R_con{
		padding: 0 5rem;
	}
	.copy-con h3{
		font-size: 2rem;
	}
	/deep/.el-form-item__label{
		width: 9rem !important;
		font-size: 1.6rem;
	}
	/deep/.el-form-item__content{
		margin-left: 9.5rem !important;
	}
	/deep/.el-input__inner{
		font-size: 1.6rem;
		height: 3rem;
	}
	/deep/.el-button{
		font-size: 1.6rem;
	}
	.center{
		display: none;
	}
	.Left{
		width: 48%;
	}
	.Right{
		width: 48%;
	}
	.line_box{
		height: 20rem;
		padding: 0 !important;
	}
}
</style>